import React, { useEffect, useState } from "react";
import { createClient } from "@supabase/supabase-js";
import { useNavigate } from "react-router-dom";
import { LoginContext } from "./index";

const LoginProvider = (props) => {
  const supabase = createClient(
    "https://akfgvcxwqbfwlprawjgj.supabase.co",
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImFrZmd2Y3h3cWJmd2xwcmF3amdqIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODg5ODcyNjMsImV4cCI6MjAwNDU2MzI2M30.4p0MJGvRClbWSYEPMUnzPEDpHNKuA9QisCHku1uEj7w"
  );
  const [userData, setUserData] = useState({});
  const [themeDark, setThemeDark] = useState(false);
  const navigate = useNavigate();
  const local_user = JSON.parse(localStorage.getItem("user"));
  const htmlElement = document.documentElement;
  // change theme Light-Dark
  useEffect(() => {
    // const doc = document.getElementById("root");
    // themeDark ? (doc.className = "dark") : (doc.className = "light");

    htmlElement.setAttribute('data-theme',themeDark?'dark':'light' );
    if (local_user) {
      updateUserTheme();
      addUserUpdate(local_user);
    }
  }, [themeDark]);

  // update user theme data
  const updateUserTheme = async () => {
    const { error } = await supabase
      .from("user_table")
      .update({ theme: themeDark ? "dark" : "light" })
      .eq("id", local_user.id);
  };

  const addUserUpdate = async (userData) => {
    const { data } = await supabase.from("user_table").select();
    const user = data.find((item) => item.id === userData.id);
    if (!user) {
      const { error } = await supabase
        .from("user_table")
        .insert({ id: userData.id, email: userData.email });
    } else {
      user.theme === "dark" ? setThemeDark(true) : setThemeDark(false);
      // user.url ? navigate(user.url) : navigate("/");
    }
  };

  // Login user though email and password
  const LoginUser = async (user) => {
    const { data, error } = await supabase.auth.signInWithPassword({
      email: user.email,
      password: user.password,
    });
    if (data) {
      // setuser(data.user);
      localStorage.setItem("user", JSON.stringify(data.user));
      addUserUpdate(data.user);
    }
    if (!error) {
      navigate("/");
    }
  };

  // Register New User
  const RegisterNewUser = async (user) => {
    const { data, error } = await supabase.auth.signUp({
      email: user.email,
      password: user.password,
    });
    if (!error) {
      navigate("/signin");
    }
  };

  // Logout User
  const LogoutUser = async () => {
    const { error } = await supabase.auth.signOut();
    const user = localStorage.getItem("user");
    // if (user) {
    //   const { error } = await supabase
    //     .from("user_table")
    //     .update({ theme: themeDark ,url:})
    //     .eq("id", 1);
    // }
    localStorage.removeItem("user");
    navigate("/signin");
  };

  return (
    <LoginContext.Provider
      value={{
        supabase,
        userData,
        themeDark,
        setUserData,
        setThemeDark,
        RegisterNewUser,
        LoginUser,
        LogoutUser,
        ...props,
      }}
    >
      {props.children}
    </LoginContext.Provider>
  );
};

export default LoginProvider;
